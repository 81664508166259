.footer {
    text-align: center;
    margin-top: 20px;
    padding: 10px 0;
    font-size: 0.9em;
    color: #555;
}

.footer a {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
}

/* Style principal du formulaire */
.checkout-form .card-element-container {
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style du bouton de paiement avec un sélecteur plus spécifique */
.checkout-form .card-element-container + button,
.checkout-form form button[type="submit"] {
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(135deg, #ff4b4b, #d10000);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: auto;
  margin-right: auto;
}

.checkout-form .card-element-container + button:hover:not(:disabled),
.checkout-form form button[type="submit"]:hover:not(:disabled) {
  background: linear-gradient(135deg, #d10000, #ff4b4b);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(209, 0, 0, 0.2);
}

.checkout-form .card-element-container + button:disabled,
.checkout-form form button[type="submit"]:disabled {
  background: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Style du spinner de chargement */
.checkout-form .loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.checkout-form .spinner {
  border: 4px solid rgba(255, 75, 75, 0.1);
  border-top: 4px solid #ff4b4b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Message d'erreur */
.checkout-form .error-message {
  color: #d10000;
  background-color: #ffe6e6;
  padding: 12px;
  border-radius: 6px;
  margin: 15px 0;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ffcccc;
}

/* Animation du spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Styles responsifs */
@media (max-width: 768px) {
  .card-element-container {
    padding: 12px;
    margin: 15px 0;
  }

  button {
    padding: 12px 16px;
    font-size: 14px;
  }

  .error-message {
    font-size: 13px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .card-element-container {
    padding: 10px;
    margin: 10px 0;
  }

  button {
    padding: 10px 14px;
    font-size: 13px;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }
}
  
.employee-card {
    background: #f5f5f5;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
  }
  
  .employee-card:hover {
    background: #e0e0e0;
  }
  
  .employee-card h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .employee-card p {
    color: gray;
  }
  
.take-order-container {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  margin-top: 100px;
}

.take-order-message {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.loading-spinner {
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #df4040;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
}

.comparaison-date {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.calendar h1, .calendar h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.calendar h2 {
  font-size: 22px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
}

.calendar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}

.calendar-header {
  display: grid;
  grid-template-columns: repeat(7, 40px);
  gap: 4.5px;
  justify-content: center;
  margin-bottom: 3px;
  width: fit-content;
  margin: 0 auto;
}

.calendar-header span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #666;
  padding: 10.5px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 40px);
  gap: 3px;
  width: fit-content;
  margin: 0 auto;
}

.calendar-day {
  min-height: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 2px;
}

.calendar-day.empty {
  background-color: transparent;
  border: none;
  cursor: default;
}

.calendar-day.empty:hover {
  background-color: transparent;
}

.weeks {
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  justify-content: center;
  justify-items: center;
}

.months {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  width: fit-content;
  margin: 20px auto;
  padding: 20px;
  max-width: 600px;
  justify-content: center;
  justify-items: center;
}

.calendar-day,
.calendar-week,
.calendar-month {
  aspect-ratio: 1; /* Rend les boutons carrés */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.calendar-day:hover,
.calendar-week:hover,
.calendar-month:hover {
  background-color: #e8f4fc;
}

.calendar-day.selected,
.calendar-week.selected,
.calendar-month.selected {
  background-color: #4CAF50 !important;
  color: white;
  font-weight: bold;
  border: 2px solid black !important;
}

.compare-button, .reset-button {
  margin: 15px auto;
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: block;
  text-align: center;
}

.compare-button:hover, .reset-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.reset-button {
  background-color: #FF6B6B;
}

.reset-button:hover {
  background-color: #e94a4a;
}

.chart-container {
  width: 100%;
  max-width: 800px;
  height: 400px;
  margin: 30px auto;
}

.month-selector {
  width: 15%;
  margin-left: auto;
  margin-right: auto;
}

.year-selector, .my-month-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  flex-wrap: wrap;
  gap: 10px;
}

.year-selector select, .year-select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
  width: 100px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .calendar h1 {
    font-size: 24px;
  }

  .calendar h2 {
    font-size: 18px;
  }

  .compare-button, .reset-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .weeks {
    grid-template-columns: repeat(6, 1fr);
  }

  .months {
    grid-template-columns: repeat(3, 1fr); /* 3 colonnes sur tablettes */
  }

  .month-selector {
    width: 20%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .calendar h1 {
    font-size: 20px;
  }

  .calendar h2 {
    font-size: 16px;
  }

  .calendar-day, .calendar-week, .calendar-month {
    font-size: 12px;
    padding: 5px;
  }

  .compare-button, .reset-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .chart-container {
    height: 300px;
  }

  .weeks {
    grid-template-columns: repeat(4, 1fr);
  }

  .months {
    grid-template-columns: repeat(2, 1fr);
  }

  .month-selector {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .calendar-header {
    grid-template-columns: repeat(7, 35px);
    gap: 4.5px;
  }

  .calendar-header, .days {
    margin: -4px;
  }

  .calendar-header span {
    padding: 6px;
    font-size: 13px;
  }

  .days {
    grid-template-columns: repeat(7, 35px);
    gap: 2px;
  }

  .calendar-day {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .calendar-header {
    grid-template-columns: repeat(7, 30px);
  }

  .calendar-header span {
    padding: 5px;
    font-size: 12px;
  }

  .days {
    grid-template-columns: repeat(7, 30px);
  }

  .calendar-day {
    width: 30px;
    height: 30px;
    font-size: 11px;
  }
}

.weekly-calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px auto;
}

.week-buttons-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 semaines par ligne */
  gap: 10px;
  max-width: 600px; /* Largeur maximale */
  margin: 0 auto; /* Centre le conteneur horizontalement */
  justify-content: center; /* Centre les boutons horizontalement */
}

.week-button {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.week-button:hover {
  background-color: #e8f4fc;
}

.week-button.selected {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  border-color: #4CAF50;
}

.calendar {
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar h2 {
  text-align: center;
}

.calendar-week {
  width: 55px;
  height: 55px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.calendar-week:hover {
  background-color: #e8f4fc;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.calendar-week.selected {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  border: 2px solid #2E7D32;
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.3);
}

@media (max-width: 1200px) {
  .weeks {
    grid-template-columns: repeat(10, 1fr);
    max-width: 600px;
  }
}

@media (max-width: 992px) {
  .weeks {
    grid-template-columns: repeat(8, 1fr);
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .weeks {
    grid-template-columns: repeat(6, 1fr);
    max-width: 400px;
  }
}

@media (max-width: 576px) {
  .weeks {
    grid-template-columns: repeat(4, 1fr);
    max-width: 300px;
  }
  
  .calendar-week {
    width: 50px;
    height: 50px;
    font-size: 11px;
  }
}

.calendar-month {
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.calendar-month:hover {
  background-color: #e8f4fc;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.calendar-month.selected {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  border: 2px solid #2E7D32;
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.3);
}

/* Responsive design pour les mois */
@media (max-width: 992px) {
  .months {
    grid-template-columns: repeat(3, 1fr);
    max-width: 450px;
  }
}

@media (max-width: 768px) {
  .months {
    grid-template-columns: repeat(2, 1fr);
    max-width: 300px;
  }
  
  .calendar-month {
    width: 110px;
    height: 55px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .months {
    grid-template-columns: repeat(2, 1fr);
    max-width: 280px;
    gap: 10px;
  }
  
  .calendar-month {
    width: 100px;
    height: 50px;
    font-size: 12px;
  }
}


.manage-tables-container {
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 400px;
    margin: auto;
    background-color: #f9f9f9;
  }
  
  .manage-tables-container h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .manage-tables-container p {
    font-size: 1em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .manage-tables-actions {
    margin-top: 20px;
  }
  
  .manage-tables-input {
    padding: 10px;
    width: 200px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .manage-tables-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
  }
  
  .manage-tables-button.save {
    background-color: #007BFF;
    color: #fff;
  }
  
  .manage-tables-button.save:hover {
    background-color: #0056b3;
  }
  
  .manage-tables-button.delete {
    background-color: #FF0000;
    color: #fff;
  }
  
  .manage-tables-button.delete:hover {
    background-color: #cc0000;
  }
  
  .manage-tables-delete {
    margin-top: 20px;
  }
  
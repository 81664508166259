.chart-container {
    width: 400px;  /* Ajustez la taille ici */
    height: 600px; /* Ajustez la taille ici */
    margin: 0 auto; /* Centre les graphiques */
  }
  
  /* Conteneur principal */
.statistics-section {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.statistics-section h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.statistics-section h2 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #555;
}

.statistics-section p {
  text-align: center;
  font-size: 1rem;
  margin: 10px 0;
  color: #444;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  max-width: 600px;
  height: 500px; /* Supprimez une hauteur fixe */
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.statistics-section canvas {
  width: 100% !important;
  height: auto !important; /* Laissez Chart.js gérer le ratio */
  max-width: 100%; /* Empêche les débordements */
}

/* Liste des statistiques */
.stat-block p {
  margin: 10px 0;
  font-size: 1.1rem;
}

.statistics-section p strong {
  font-weight: bold;
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chart-container {
  animation: fadeIn 1s ease-in-out;
}

.statistics-section {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.stat-block {
  margin-bottom: 30px;
  padding: 15px;
  border-left: 4px solid #13aa16;
  background-color: #cfffc0;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.stat-block:hover {
  background-color: #8fd47f;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.stat-block h2 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.5rem;
}

.stat-block p {
  margin: 5px 0;
  font-size: 1.1rem;
  color: #555;
}

.highlight {
  font-weight: bold;
  color: #084411;
  font-size: 1.2rem;
}

.revenue-section p {
  margin: 8px 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.statistics-section {
  animation: fadeInUp 1s ease-in-out;
}

@media (max-width: 768px) {
  .statistics-canvas .chart-container {
    min-height: 300px;
    height: 100%;
    padding: 10px;
    margin: 10px 0px;
  }

  .statistics-canvas canvas {
    max-width: 100%;
    height: auto !important;
  }

  .statistics-section h1 {
    font-size: 1.3rem;
    text-align: center;
  }

  .statistics-section h2 {
    font-size: 1.3rem;
    text-align: center;
  }

  .stat-block p {
    font-size: 0.85rem;
    text-align: left;
  }

  .statistics-section p {
    font-size: 0.85rem;
    text-align: left;
  }

  .highlight {
    font-size: 0.85rem;
  }

  .statistics-section p,
  .stat-block p {
    text-align: left;
  }
}

.radio-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.radio-container input[type="radio"] {
  display: none;
}

.radio-container label {
  margin: 0 10px;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.radio-container input[type="radio"]:checked + label {
  background-color: #13aa16;
  color: white;
}

.radio-container label:hover {
  background-color: #e0e0e0;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #dfdfdf;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.server-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  color: black;
  position: sticky;
  width: 95%;
  height: 100px;
  margin: 20px auto auto;
  margin-left: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.5s ease-out;
  padding-top: 20px;
}

.server-header .logo {
  font-size: 1.5rem;
  font-weight: bold;
  animation: fadeIn 1s ease-in;
}

.server-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.server-nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.server-nav ul li {
  cursor: pointer;
  position: relative;
  padding: 3px 20px;
  background-color: #df4040;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  min-width: 140px;
  transition: color 0.3s, transform 0.2s;
  align-content: center;
  min-height: 40px;
}

.server-nav ul li:hover,
.server-nav ul li.active {
  color: white;
  background-color: #c51414;
}

.server-nav ul li:hover {
  transform: scale(1.1);
}

.logout-icon {
  display: none;
}

.menu-toggle {
  display: none;
  font-size: 1.5rem;
  color: black;
  background: none;
  border: none;
  cursor: pointer;
  animation: fadeIn 1s ease-in;
  margin-bottom: 30px;
}

.menu-toggle:hover {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.server-main {
  padding: 1rem;
  animation: fadeIn 1.2s ease-in-out;
  margin-top: 30px;
}

.welcome-message {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  animation: slideUp 0.7s ease-out;
}

/* Animations */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .server-nav {
    display: none;
    flex-direction: column;
    background-color: #df4040;
    color: white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.5s ease-in-out;
  }

  .server-nav.open {
    display: flex;
  }

  .server-nav ul {
    flex-direction: column;
    gap: 1.1rem;
  }

  .server-nav ul li {
    margin: 0.5rem 0;
    box-shadow: none;
    padding: 5px 50px;
  }

  .menu-toggle {
    display: block;
  }
}

.unauthorized-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.unauthorized-popup {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-out;
}

.unauthorized-popup h2 {
  margin-bottom: 10px;
  color: #D9534F;
}

.unauthorized-popup p {
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.server-interface-container .hamburger {
  display: block;
  font-size: 24px;
  background: none;
  border: 2px solid #555555;
  color: #555555;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative;
  margin-left: 20px;
  margin-top: 0;
}

.server-interface-container .hamburger div {
  content: '';
  display: block;
  width: 70%;
  height: 3px;
  background-color: #555555;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.server-interface-container .hamburger div:nth-child(1) {
  top: 30%;
  transform: translate(-50%, -50%) rotate(0deg);
}

.server-interface-container .hamburger div:nth-child(2) {
  top: 50%;
  transform: translate(-50%, -50%);
}

.server-interface-container .hamburger div:nth-child(3) {
  top: 70%;
  transform: translate(-50%, -50%) rotate(0deg);
}

.server-interface-container .hamburger.open div:nth-child(1) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.server-interface-container .hamburger.open div:nth-child(2) {
  opacity: 0;
}

.server-interface-container .hamburger.open div:nth-child(3) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.server-interface-container .hamburger-menu {
  display: none;
  flex-direction: column;
  background-color: #555555;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 100;
}

.server-interface-container .hamburger-menu.open {
  display: flex;
}

.server-interface-container .hamburger-item {
  padding: 15px 15px 0 15px;
  color: white;
  cursor: pointer;
}

.server-interface-container .hamburger-category-label {
  font-size: 18px;
}

.server-interface-container .hamburger-item:last-child {
  padding-bottom: 15px;
}

.logout-button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
}

@media (max-width: 1023px) {
  .server-nav {
    display: none;
  }
  
  .server-interface-container .hamburger {
    display: block;
  }
  
  .logout-left {
    display: none;
  }

  .order-right {
    display: flex !important;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 1024px) {
  .server-interface-container .hamburger {
    display: none;
  }
  
  .server-interface-container .hamburger-menu {
    display: none;
  }
  
  .server-nav {
    display: flex;
  }
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #555555;
  width: 25px;
  height: 25px;
  transition: color 0.3s, transform 0.3s;
}

.icon-wrapper:hover {
  color: #838383;
  transform: scale(1.1);
}

.header-icon {
  width: 100%;
  height: 100%;
}

@media (max-width: 1023px) {
  .header-icons {
    gap: 15px;
    margin-right: 15px;
  }
  
  .icon-wrapper:last-child {
    display: none;
  }
}

.logout-left {
  margin-left: 20px;
  margin-top: -20px;
}

.order-right {
  margin-right: 20px;
  margin-top: -20px;
}

@media (max-width: 1023px) {
  .logout-left {
    display: none;
  }

  .order-right {
    margin-top: 0;
  }
}

.header-icons,
.logout-icon,
.server-nav ul li.logout-btn {
  display: none;
}

.logout-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.logout-confirm-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.logout-confirm-popup h3 {
  margin-top: 0;
  color: #333;
}

.logout-confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.logout-confirm-buttons button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.confirm-btn {
  background-color: #df4040;
  color: white;
}

.confirm-btn:hover {
  background-color: #c51414;
}

.cancel-btn {
  background-color: #6c757d;
  color: white;
}

.cancel-btn:hover {
  background-color: #5a6268;
}


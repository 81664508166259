/* Avis.css */

.avis-container {
  font-family: 'Arial', sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.avis-container p {
  font-size: 0.8rem;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.feedbacks-container {
  background: #fff;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.feedbacks-container h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.feedbacks-container ul {
  list-style-type: none;
  padding: 0;
}

.feedbacks-container ul li {
  background-color: #f4f4f4;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedbacks-container ul li:last-child {
  margin-bottom: 0;
}

.feedbacks-container p {
  font-size: 14px;
  color: #777;
  text-align: center;
}

.feedback-date {
  color: #888;
  font-size: 12px;
  margin-left: 15px;
}

.feedback-text {
  flex: 1;
  color: rgba(255, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .avis-container {
    padding: 15px;
  }

  .average-ratings p, .feedbacks-container h3 {
    font-size: 14px;
  }

  .feedbacks-container ul li {
    font-size: 13px;
  }

  .avarage-message {
    font-size: 14px;
  }
}

.progress-bar {
  position: relative; 
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  width: 80%;
  margin-top: 30px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  width: 0;
  transition: width 1s ease-in-out;
}

.avarage-message {
  margin-bottom: 35px;
  margin-left: 10px;
}

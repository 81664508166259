.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }

  @media (max-width: 1200px) {
    .login-container {
      width: 90%;
      margin-top: 25%;
    }
  }
  
  @media (max-width: 768px) {
    .login-container {
      width: 90%;
      margin-top: 25%;
    }
  }
  
  @media (max-width: 480px) {
    .login-container {
      width: 90%;
      margin-top: 25%;
    }
  }
  
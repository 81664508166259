/* src/components/Paiement.css */

body {
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f5f7;
}

.payment-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.payment-container h1 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.payment-container h3 {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 30px;
}

.payment-container p {
  font-size: 1rem;
  color: #7f8c8d;
}

.loading-spinner {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .payment-container {
    margin: 20px;
    margin-top: 40px;
    padding: 15px;
  }

  .payment-container h1 {
    font-size: 1.8rem;
  }

  .payment-container h3 {
    font-size: 1.3rem;
  }
}

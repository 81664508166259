h1 {
  text-align: center;
  margin-bottom: 20px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  h3 {
    text-align: center;
    margin: 20px 0;
    color: #555;
  }

  div > button {
    margin: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #c41e3a;
    border-radius: 4px;
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  div > button:hover {
    background: linear-gradient(135deg, #c41e3a, #ff6b6b);
    transform: translateY(-2px);
  }
  
  div > button:active {
    transform: scale(0.97);
  }
  
  /* Liste des éléments */
  .view-menu-items-list {
    list-style: none;
    padding: 0;
    margin: 20px auto;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 0 20px;
    justify-items: center;
  }
  
  li {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
  }
  
  li:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  li img {
    display: block;
    margin: 10px 0;
    border-radius: 4px;
  }
  
  /* Texte dans les éléments */
  li strong {
    color: #333;
    font-size: 1.4rem;
  }
  
  li span {
    font-weight: bold;
    color: #c41e3a;
  }
  
  /* Boutons dans les éléments */
  li button {
    padding: 8px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  /* Style de la popup */
  .popup-confirmation {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 25px;
    background-color: #4caf50; /* Vert pour les confirmations */
    color: #fff;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    animation: fadeInOut 2s forwards;
  }
  
  /* Animation pour le popup */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    20%, 80% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

  .popup-confirmation {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4caf50; /* Couleur verte */
    color: white;
    padding: 15px;
    border-radius: 5px;
    opacity: 1;
    transition: opacity 0.5s ease;
    z-index: 1000; /* S'assurer que la pop-up est au-dessus des autres éléments */
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  
  .view-menu-items-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }

  .view-menu-items-buttons button {
    padding: 8px 15px;
    min-width: 80px;
    flex: 0 1 calc(40% - 10px);
    max-width: 150px;
  }

  @media screen and (max-width: 480px) {
    .view-menu-items-buttons {
      width: 100%;
      padding: 0 10px;
    }

    .view-menu-items-buttons button {
      flex: 0 1 calc(45% - 5px);
      max-width: none;
    }
  }
  
  .image-container {
    width: 190px;
    height: 100px;
    margin: 10px auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu-item-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .add-photo-button {
    background-color: #4889e9;
  }

  .view-supp-button {
    background-color: #c41e3a;
    margin-top: 15px;
  }
  
  .cart-view {
    cursor: default;
    text-align: center;
    padding: 25px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  @media screen and (max-width: 768px) {
    .view-menu-items-list {
      grid-template-columns: 1fr;
    }
  }
  
  /* Style pour le texte du modifier */
  .cart-view span {
    display: inline-block;
    margin: 15px 0 0;
  }

  .title-classname-product {
    margin-top: 0 !important;
  }

  .menu-item-price {
    color: #333;
    font-size: 1.2rem;
  }
  
  .popup-overlay-supplement {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Fond plus translucide */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-supplement {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 60vw;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .supplement-category {
    margin-top: 10px;
  }
  
  .supplement-label {
    display: block;
    margin: 5px 0;
  }
  
  /* ✅ Popup générale (overlay) */
.popup-overlay-supplements {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);  /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
}

/* ✅ Animation de la popup */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ✅ Contenu de la popup (popup-content) */
.popup-content-supplements {
  background: #fff;
  border-radius: 16px;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  animation: popupIn 0.4s ease-in-out;
  z-index: 1001;
  position: relative;
}

/* ✅ Animation d'apparition de la popup */
@keyframes popupIn {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* ✅ Header de la popup (titre + bouton de fermeture) */
.popup-header-supplements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header-supplements h3 {
  font-size: 24px;
  color: #333;
  margin: auto;
  position: absolute;
  width: 94%;
}

.close-button-supplements {
  background: none;
  border: none;
  font-size: 24px;
  color: #888;
  cursor: pointer;
  transition: color 0.2s;
  background: none;
  z-index: 100;
}

.close-button-supplements:hover {
  color: #333;
  background: none !important; 
}

/* ✅ Corps de la popup (contient les suppléments) */
.popup-body-supplements {
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 20px;
}

.supplement-category-body {
  margin-bottom: 20px;
}

.supplement-items-popup {
  margin-bottom: 30px;
}

.supplement-items-popup label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f7f7f7;
  cursor: pointer;
  transition: background 0.3s ease;
}

.supplement-items-popup label:hover {
  background-color: #e1e1e1;
}

.supplement-items-popup input[type="checkbox"] {
  accent-color: #007bff;
  cursor: pointer;
}

/* ✅ Footer avec les boutons Valider et Annuler */
.popup-footer-supplements {
  display: flex;
  justify-content: space-between;
}

.popup-footer-supplements button {
  padding: 12px 25px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease;
}

.validate-button-popup {
  background-color: #007bff;
  color: white;
}

.validate-button-popup:hover {
  background-color: #0056b3;
}

/* ✅ Responsive design pour mobile/tablette */
@media (max-width: 768px) {
  .popup-content-supplements {
    width: 90%;
    max-height: 70vh;
  }

  .popup-header-supplements h3 {
    font-size: 18px;
  }

  .popup-footer-supplements button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .popup-content-supplements {
    width: 95%;
    max-height: 80vh;
  }

  .popup-header-supplements h3 {
    font-size: 16px;
  }

  .popup-footer-supplements button {
    font-size: 12px;
    padding: 8px 16px;
  }
}

.supplements-popup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  margin-top: 20px;
}

.edit-supplements-button, .add-supplements-button {
  padding: 8px 16px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: auto;
}

.category-name-popup {
  text-align: center;
  font-size: 20px;
  border-bottom: 1px solid black;
  width: 40%;
  margin: 0 auto 20px auto;
  font-weight: 700;
}

.edit-supplements-button:hover, .add-supplements-button:hover {
  background-color: #0056b3;
}

.checkbox-label-supplements {
  cursor: pointer;
}

.checkbox-label-supplements input {
  display: none;
}

.checkbox-label-supplements svg {
  overflow: visible;
  width: 20px;
}

.path {
  fill: none;
  stroke: black;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.5s ease;
  stroke-dasharray: 0 0 240 9999999;
  stroke-dashoffset: 1;
  scale: -1 1;
  transform-origin: center;
  animation: hi 0.5s;
}

.checkbox-label-supplements input:checked ~ svg .path {
  stroke-dasharray: 0 262 70 9999999;
  transition-delay: 0s;
  scale: 1 1;
  animation: none;
}

@keyframes hi {
  0% {
    stroke-dashoffset: 20;
  }
  to {
    stroke-dashoffset: 1;
  }
}

.show-availible-supp {
  text-align: center;
  margin: auto;
}

/* Style de la catégorie */
.supplement-category-popup {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  width: 95%;
}

.supplement-category-popup:hover {
  transform: translateY(-5px);
}

/* Titre de la catégorie */
.catrgory-title-supp {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

/* Liste des suppléments de la catégorie */
.catrgory-name-supp {
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
  display: block;
}

/* Séparateur virgule */
.catrgory-name-supp span {
  margin-top: 10px;
  margin-right: 5px;
  color: #555 !important;
}

/* Design des éléments */
.catrgory-name-supp span:last-child {
  margin-right: 0;
}

.item-category {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  color: #666;
  display: inline-block;
  margin-bottom: 8px;
}

.search-container {
  width: 100%;
  max-width: 300px;
  margin: 20px auto 10px;
  padding: 0 20px;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #c41e3a;
}

.no-results {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
  margin-top: 20px;
}

.item-category {
  background-color: #c41e3a;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.9em;
  display: inline-block;
  margin-bottom: 12px;
  font-weight: 500;
}

/* Ajuster la marge pour les boutons de navigation */
.view-menu-items-buttons {
  margin-top: 10px;
}

table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
  text-align: left;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

button.delete-table-hour {
  background-color: rgb(190, 0, 0);
}

td.actions-button-hour {
  text-align: center;
}

.add-hour-supp {
  width: 40%;
  margin: 30px auto;
  display: block;
}

.label-add-extra {
  text-align: center;
  margin-bottom: 10px;
}

.label-add-extra select, .label-add-extra input {
  width: 40%;
}

@media screen and (max-width: 768px) {
  .work-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .label-add-extra select, .label-add-extra input {
    width: 70%;
  }

  table {
    width: 100%;
    min-width: 600px;
    margin: 0;
  }
}

.button-container {
  display: flex;
  gap: 1.5rem;
  margin: 2rem auto;
  justify-content: center;
  max-width: 800px;
}

.send-report-button,
.download-excel-button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: white;
  transition: all 0.2s ease;
  min-width: 250px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.send-report-button {
  background-color: #ff6b6b;
}

.send-report-button:hover {
  background-color: #ff5252;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.download-excel-button {
  background-color: #ff6b6b;
}

.download-excel-button:hover {
  background-color: #ff5252;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 768px) {
  .button-container {
    flex-direction: column;
    padding: 0 1rem;
  }

  .send-report-button,
  .download-excel-button {
    width: 100%;
    min-width: unset;
  }
}
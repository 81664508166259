.mypaiement {
  padding: 20px;
  background: #f5f5f5;
  font-family: 'Arial', sans-serif;
}

.mypaiement-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.mypaiement-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

.mypaiement-container p {
  text-align: center;
}

.paiement-table {
  text-align: center;
  margin-bottom: 20px !important;
  font-size: 1.2rem !important;
  font-weight: bold;
}

.client-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.client-card h2 {
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 10px;
}

.client-card p {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}

.details-card-command {
  font-size: 1.1rem;
  color: #333;
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}

.total-card-command {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
  margin-top: 15px;
}

.client-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.client-card ul li {
  font-size: 1rem;
  color: #444;
  margin-bottom: 15px;
  padding: 10px;
}

.client-card button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 0.9rem;
  color: white;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.name-card-total {
  font-size: 1.1rem;
}

.client-card button:hover {
  background: #0056b3;
}

.delete-button-client {
  background: rgb(190, 1, 1) !important;
}

.delete-button-client:hover {
  background: rgb(160, 0, 0) !important;
}

.client-card button:last-child {
  background: #28a745;
}

.client-card button:last-child:hover {
  background: #1e7e34;
}

.client-card button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.accept-button {
  display: flex;
  flex-direction: column; /* Empile les boutons verticalement */
  justify-content: center; /* Centre verticalement */
  align-items: center; /* Centre horizontalement */
  margin-top: 20px; /* Facultatif, ajoute de l'espace au-dessus */
  margin-bottom: 20px;
}

.accept-button button {
  width: 210px;
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accept-button button:hover {
  opacity: 0.9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mypaiement-title {
    font-size: 1.8rem;
  }

  .mypaiement-container {
    gap: 15px;
  }

  .client-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .mypaiement-title {
    font-size: 1.5rem;
  }

  .client-card h2 {
    font-size: 1.3rem;
  }

  .client-card button {
    font-size: 0.8rem;
    padding: 8px 12px;
    width: 80%;
  }

  .client-card ul li {
    font-size: 1rem;
  }
}

.modal-overlay-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-popup {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.modal-content-popup button {
  background: #007bff;
  background-color: #007bff;
  border: none;
}

.modal-content-popup button:hover {
  background: #0056b3;
  background-color: #0056b3;
}

.payment-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
}

.payment-button {
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.payment-button.selected {
  background-color: #013f82;
  color: white;
  border: 1px solid #002b59;
}

.modal-actions-popup {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.validate-button-paimement-popup, .cancel-button-popup-paiement {
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.validate-button-paimement-popup {
  background-color: #4CAF50 !important;
  color: white;
}

.validate-button-paimement-popup:disabled {
  background-color: #cccccc !important;
  cursor: not-allowed;
}

.cancel-button-popup-paiement {
  background-color: #f44336 !important;
  color: white;
}

.payment-method-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.payment-amount-input {
  width: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
}

.payment-amount-input:focus {
  outline: none;
}

.modal-content-popup {
  max-width: 400px;
  width: 90%;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.modal-buttons button:first-child {
  background-color: #28a745;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #dc3545;
  color: white;
}

.modal-buttons button:hover {
  opacity: 0.9;
}
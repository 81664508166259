/* Popup overlay */
.satisfaction-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.satisfaction-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1001;
}

.satisfaction-popup-content {
  background-color: #fff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Titre et sous-titre */
.satisfaction-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.satisfaction-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 25px;
  text-align: center;
}

/* Container des étoiles */
.ratings-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 25px;
}

.rating-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.rating-group label {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.stars {
  display: flex;
  gap: 8px;
}

.star {
  font-size: 32px;
  cursor: pointer;
  color: #ddd;
  transition: color 0.2s ease;
}

.star:hover {
  color: #ffd700;
}

.star.selected {
  color: #ffd700;
}

.feedback-group label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.feedback-group textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.2s ease;
}

.feedback-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

/* Boutons */
.satisfaction-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.satisfaction-submit-btn,
.satisfaction-close-btn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: #007bff;
  color: white;
}

.satisfaction-submit-btn:hover,
.satisfaction-close-btn:hover {
  background-color: #0056b3;
}

/* Messages */
.satisfaction-error-message,
.satisfaction-success-message {
  text-align: center;
  padding: 10px;
  margin-top: 15px;
  border-radius: 6px;
}

.satisfaction-error-message {
  background-color: #fff3f3;
  color: #dc3545;
}

.satisfaction-success-message {
  background-color: #f3fff3;
  color: #28a745;
}

/* Responsive Design */
@media (min-width: 768px) {
  .satisfaction-buttons {
    flex-direction: row;
    justify-content: center;
  }

  .satisfaction-submit-btn,
  .satisfaction-close-btn {
    width: auto;
    min-width: 150px;
  }
}
/* Conteneur principal centré */
.client-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 95.5vh; /* Centre verticalement dans la page */
  padding: 20px;
  background: linear-gradient(135deg, #f9f9f9, #eceff1); /* Dégradé léger */
  font-family: 'Roboto', sans-serif;
}

.your_commande {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-decoration: underline;
}

/* Titre */
.client-form-title {
  font-size: 2.2rem;
  color: #333;
  margin-bottom: 18px;
  text-align: center;
  font-weight: bold;
}

/* Formulaire stylisé */
.client-form {
  background: #fff;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
  animation: fadeIn 0.8s ease-out;
  transition: all 0.3s ease;
}

/* Groupes de champs */
.client-input-group {
  display: flex;
  flex-direction: column;
}

.client-input-group label {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 8px;
}

.client-input-group input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

/* Bouton d'envoi */
.client-submit-btn {
  margin-bottom: 25px;
  background: linear-gradient(90deg, #ff4b4b, #d10000);
  color: #fff;
  font-size: 1rem;
  padding: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-transform: uppercase;
  font-weight: bold;
}

.client-submit-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(209, 0, 0, 0.3);
}

/* Message d'erreur */
.client-error-message {
  text-align: center;
  color: #ef272b;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

/* Message de succès */
.client-success-message {
  background-color: #e6ffe6;
  color: #006400;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #98FB98;
  font-size: 0.9rem;
  animation: fadeIn 0.5s ease-out;
  margin-bottom: 20px;
}

/* Animation d'apparition */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive pour les petits écrans */
@media (max-width: 480px) {
  .client-form {
    padding: 25px;
  }

  .client-form-title {
    font-size: 1.8rem;
  }

  .client-submit-btn {
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.client-access-error {
  background-color: #fff3f3;
  color: #ff4d4f;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ffccc7;
  font-size: 0.9rem;
  animation: fadeIn 0.5s ease-out;
  margin-bottom: 20px;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: .9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
  margin: auto;
  margin-top: 25%;
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.form-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 1000;
}

.form-popup-overlay.fade-in {
  opacity: 1;
  visibility: visible;
}

.form-popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  transform: scale(0.8);
  transition: transform 0.3s ease-in-out;
}

.form-popup p {
  font-size: 1.4rem;
  margin-bottom: 30px;
  margin-top: 10px;
}

.form-popup-overlay.fade-in .form-popup {
  transform: scale(1);
}

.form-popup-btn-1 {
  background: #12c212;
  border: 1px solid #12c212;
}

.form-popup-btn-2 {
  background: #d10000;
  border: 1px solid #d10000;
}

.form-popup-btn-1:hover {
  background: #088f08;
  border: 1px solid #088f08;
}

.form-popup-btn-2:hover {
  background: #a30101;
  border: 1px solid #a30101;
}
.firestore-viewer {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.firestore-viewer h1 {
  text-align: center;
  color: #333;
}

.firestore-panel {
  display: flex;
  gap: 20px;
}

.firestore-collections,
.firestore-documents,
.firestore-document-details {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 30%;
  height: 400px;
  overflow-y: auto;
}

.firestore-collections h2,
.firestore-documents h2,
.firestore-document-details h2 {
  margin-top: 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

li:hover {
  background-color: #f5f5f5;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.edit-field {
  margin: 10px 0;
}

.edit-field label {
  display: inline-block;
  width: 150px;
  margin-right: 10px;
}

.edit-field input {
  padding: 5px;
  width: 200px;
}

.edit-buttons {
  margin-top: 20px;
}

.edit-buttons button {
  margin-right: 10px;
  padding: 5px 15px;
  cursor: pointer;
}

.schedule-editor {
  margin: 15px 0;
}

.day-schedule {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.day-schedule h4 {
  margin: 0 0 10px 0;
}

.time-inputs {
  display: flex;
  gap: 15px;
}

.time-input {
  display: flex;
  align-items: center;
  gap: 5px;
}

.time-input input[type="time"] {
  padding: 5px;
}

.add-satisfaction-form {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.add-satisfaction-form input {
  margin: 5px 0;
  padding: 5px;
}

.add-satisfaction-form button {
  margin: 5px 5px 5px 0;
  padding: 5px 10px;
}

.add-satisfaction-form textarea {
  width: 100%;
  margin: 5px 0;
  padding: 5px;
  resize: vertical;
}

.firestore-documents li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.delete-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #cc0000;
}

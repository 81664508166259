.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
    position: fixed;
    inset: 0;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
    width: 90%;
    max-width: 500px;
  }
  
  .popup-content form {
    display: flex;
    flex-direction: column;
  }
  
  .popup-content input {
    margin: 5px 0;
    padding: 8px;
  }
  
  .popup-content button {
    margin-top: 10px;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-content .close-button {
    background-color: #f44336;
    margin-top: 10px;
  }
  
  .day-schedule {
    margin-bottom: 10px;
  }
  
  .time-inputs {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .period {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .period span {
    min-width: 80px;
  }
  
  .period input[type="time"] {
    width: 120px;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
  }
  
  .button-group button {
    flex: 1;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    background-color: #FF5A5F;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content button[type="submit"] {
    background-color: #2196F3;
  }
  
  .popup-content .close-button {
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    background-color: #FF5A5F;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  body.popup-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
  
  .shift-container {
    margin-bottom: 15px;
  }
  
  .shift-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .shift-inputs input[type="time"] {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 120px;
  }
  
  .remove-shift {
    background: #ff4444;
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  
  .add-shift {
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%;
  }
  
  .add-shift:hover {
    background: #45a049;
  }
  
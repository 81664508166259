.supplements-container {
    padding: 20px;
  }
  
  .categories {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .category {
    margin: 0;
    width: 100%;
    background-color: #f7fafc;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .add-button {
    flex: 1;
    max-width: 250px;
    min-width: 160px;
    white-space: nowrap;
  }
  
  .add-button .mobile-text {
    display: none;
  }
  
  .add-button .desktop-text {
    display: inline;
  }
  
  .popup {
    width: 90%;
    max-width: 500px;
    margin: 20px;
    padding: 20px;
  }
  
  .popup > input,
  .popup > select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .edit-category-button {
    margin-top: 10px;
    padding: 8px 18px;
    color: white;
    border: none;
    cursor: pointer;
    margin: auto;
    width: 100%;
  }
  
  .edit-category-button:hover {
    background-color: #e0a800;
  }

  .selection-type-choice :focus {
    outline: 0;
    border-color: #ff5757;
    box-shadow: 0 0 0 4px #fcb5b5;
  }
  
  .selection-type-choice div {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    margin-bottom: 15px;
    justify-content: center;
  }
  
  .selection-type-choice input[type="radio"] {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .selection-type-choice input[type="radio"]:checked + span {
    box-shadow: 0 0 0 0.0625em #ed0000;
    background-color: #ffdede;
    z-index: 1;
    color: #ed0000;
  }
  
  .selection-type-choice label span {
    display: block;
    cursor: pointer;
    background-color: #fff;
    padding: 0.375em .75em;
    position: relative;
    margin-left: .0625em;
    box-shadow: 0 0 0 0.0625em #d9b5b5;
    letter-spacing: .05em;
    color: #633e3e;
    text-align: center;
    transition: background-color .5s ease;
  }
  
  .selection-type-choice label:first-child span {
    border-radius: .375em 0 0 .375em;
  }
  
  .selection-type-choice label:last-child span {
    border-radius: 0 .375em .375em 0;
  }
  
  .actions-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    padding: 20px;
  }

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
}

.popup {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.3s ease-out;
}

.popup h3 {
  margin: 0 0 1.5rem 0;
  color: #2d3748;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.popup ul {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem 0;
}

.popup li {
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  background-color: #f7fafc;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.popup li:hover {
  background-color: #edf2f7;
}

.popup li div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.popup li span {
  flex: 1;
  color: #4a5568;
  cursor: pointer;
  padding: 0.25rem 0;
}

.popup input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.popup button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.popup button.delete-button {
  background-color: #fc8181;
  color: white;
}

.popup button.delete-button:hover {
  background-color: #f56565;
}

.popup .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .popup {
    width: 95%;
    padding: 15px;
  }

  .popup input,
  .popup select,
  .popup button {
    width: 100%;
    margin-bottom: 10px;
  }

  .popup .button-group {
    flex-direction: column;
  }

  .popup li div {
    flex-direction: column;
    gap: 0.5rem;
  }

  .selection-type-choice div {
    flex-direction: column;
    gap: 10px;
  }

  .selection-type-choice label span {
    border-radius: 6px !important;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .supplements-container {
    padding: 10px;
    width: 100%;
  }

  .categories {
    grid-template-columns: 1fr;
    padding: 10px;
    width: 100%;
    margin: 0;
  }

  .category {
    padding: 15px;
    width: 100%;
  }

  .actions-buttons {
    padding: 10px;
    gap: 0px;
  }

  .add-button .mobile-text {
    display: inline;
    font-size: 0.8rem;
  }
  
  .add-button .desktop-text {
    display: none;
    font-size: 0.8rem;
  }
}

.category-title {
  border-bottom: 2px solid #c5c5c5;
}

.category-items {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 15px;
  padding: 0 10px;
}

.supplement-item:last-child {
  margin-bottom: 10px;
}

.supplement-item {
  display: flex;
  align-items: center;
  padding: 8px;
}

.supplement-item span {
  color: #333;
  font-size: 0.95rem;
  position: relative;
  padding-left: 15px;
}

.supplement-item span::before {
  content: "-";
  position: absolute;
  left: 0;
}

.popup .supplement-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.popup .supplement-item span {
  flex: 1;
  cursor: pointer;
}

.popup .supplement-item .delete-button {
  padding: 6px 12px;
  font-size: 0.9rem;
}

.popup .category-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup .category-items li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.popup .category-items li span {
  cursor: pointer;
}

.popup .category-items li div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.popup .category-items li div button {
  padding: 6px 12px;
  font-size: 0.95rem;
  min-width: 110px;
  margin: 0 2px;
}

.popup .category-items li div input {
  padding: 6px 10px;
  margin: 0 2px;
}

.popup .category-items li div span {
  flex: 1;
  text-align: left;
}

.delete-button {
  margin-left: auto;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

@media (max-width: 480px) {
  .popup .category-items li div {
    gap: 5px;
  }
  
  .delete-button {
    padding: 4px 8px;
    font-size: 0.9rem;
  }
}

.nothing_here {
  margin: 20px auto;
  text-align: center;
}

.is-required-choice {
  display: flex;
  align-items: center;
  gap: 10px; /* Espacement entre la checkbox et le texte */
  font-size: 16px;
  font-weight: 500;
  color: #333; /* Couleur du texte */
  margin-top: 10px;
}

.required-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #333;
  transition: color 0.3s;
  text-align: center;
  margin: 10px auto 30px;
}

.required-label input[type="checkbox"] {
  display: none;
}

.required-label .required-checkbox {
  width: 24px;
  height: 24px;
  border: 2px solid #333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  transform-style: preserve-3d;
}

.required-label .required-checkbox::before {
  content: "\2713";
  font-size: 16px;
  color: transparent;
  transition: color 0.3s, transform 0.3s;
}

.required-label input[type="checkbox"]:checked + .required-checkbox {
  background-color: #333;
  border-color: #333;
  transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.required-label input[type="checkbox"]:checked + .required-checkbox::before {
  color: #fff;
}

.required-label:hover {
  color: #666;
}

.required-label:hover .required-checkbox {
  border-color: #666;
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.required-label input[type="checkbox"]:focus + .required-checkbox {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.required-label .required-checkbox,
.required-label input[type="checkbox"]:checked + .required-checkbox {
  transition: background-color 1.3s, border-color 1.3s, color 1.3s, transform 0.3s;
}

.max-element-choice {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
}

p.span-max-element {
  margin-top: 20px;
}

.max-element-choice input {
  width: 20%;
  text-align: center;
}

.max-element-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.decrement-btn,
.increment-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.2rem 0.5rem !important;
}

.decrement-btn svg,
.increment-btn svg {
  width: 20px;
  height: 20px;
}

.decrement-btn:hover,
.increment-btn:hover {
  background: #c5c5c5;
}

.max-element-controls span {
  margin: 0 15px;
  font-size: 16px;
}

.manage-schedule {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
  
  .employee-card {
    background: #f4f4f4;
    padding: 20px;
    margin: 10px;
    width: 200px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }
  
  .employee-card:hover {
    background-color: #e8e8e8;
  }
  
  .schedule {
    margin-top: 10px;
  }
  
  .schedule ul {
    list-style-type: none;
    padding: 0;
  }
  
  .schedule li {
    margin-bottom: 5px;
  }

.schedule-board {
  display: flex;
  flex-direction: column;
}

.days-of-week {
  display: flex;
  justify-content: space-between;
}

.day {
  width: 100px;
  text-align: center;
  font-weight: bold;
}

.schedule-rows {
  display: flex;
  flex-direction: column;
}

.schedule-row {
  display: flex;
  align-items: center;
}

.employee-name {
  width: 150px;
  font-weight: bold;
}

.schedule-cells {
  display: flex;
  position: relative;
  width: 100%;
}

.schedule-cell {
  position: relative;
  height: 30px;
  margin: 2px;
}

.schedule-hours {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 12px;
}

.schedule-table {
  display: grid;
  grid-template-columns: 120px repeat(26, 45px);
  gap: 2px;
  background-color: #f4f4f4;
  margin: 20px auto;
  width: fit-content;
  max-width: 100%;
  overflow-x: auto;
}

.schedule-header {
  display: contents;
}

.empty-cell {
  background-color: #dcdcdc;
}

.hour-cell {
  background-color: #f0f0f0;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  font-size: 0.8em;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: 80px;
}

.day-row {
  display: contents;
}

.day-cell {
  background-color: #e6e6e6;
  font-weight: bold;
  text-align: center;
  padding: 50px 0;
}

.time-cell {
  height: 110px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}

.employee-block {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 3px 5px;
  height: 20px;
  border-radius: 3px;
  font-size: 10px;
  color: white;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 5px;
  cursor: pointer;
}

.employee-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  padding: 20px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.employee-card {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  width: calc((100% - 40px) / 3);
  margin: 0;
  min-width: 200px;
  flex-grow: 0;
}

.employee-card:hover {
  background-color: #e8e8e8;
}

.todays-workers {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 70%;
  max-width: 70%;
}

.todays-workers h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.2em;
  border-bottom: 2px solid #e74c3c;
  padding-bottom: 8px;
}

.workers-grid {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.row-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  justify-content: center;
}

.worker-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 12px;
  transition: transform 0.2s ease;
  width: calc((100% - 30px) / 3);
  margin: 0;
  min-width: 200px;
  flex-grow: 0;
}

.worker-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.worker-name {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 5px;
}

.worker-role {
  color: #666;
  font-size: 0.9em;
}

.worker-schedule {
  margin-top: 8px;
  color: #e74c3c;
  font-size: 0.9em;
  font-weight: 500;
}

.legend {
  margin-top: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.legend h4 {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 1.1em;
  border-bottom: 2px solid #eee;
  padding-bottom: 8px;
  text-align: center;
}

/* Conteneur flex pour les éléments de la légende */
.legend-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.legend-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition: background-color 0.2s;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.legend-item:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.legend-color {
  width: 25px;
  height: 25px;
  margin-right: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.legend-item span {
  color: #444;
  font-size: 0.95em;
}

.legend-item.disabled {
  opacity: 0.5;
}

.legend-item.disabled span {
  text-decoration: line-through;
}

@media (max-width: 768px) {
  .manage-schedule {
    padding: 10px;
  }

  .employee-cards {
    gap: 15px;
    padding: 15px;
  }

  .employee-card {
    width: calc((100% - 15px) / 2);
    min-width: unset;
  }

  .schedule-table {
    grid-template-columns: 100px repeat(26, 35px);
    font-size: 14px;
  }

  .hour-cell {
    padding: 10px 0;
    font-size: 12px;
  }

  .day-cell {
    padding: 30px 0;
    font-size: 12px;
  }

  .employee-block {
    font-size: 9px;
    padding: 2px 3px;
  }

  .legend {
    max-width: 100%;
    margin: 20px 10px;
    padding: 12px;
  }

  .todays-workers {
    padding: 15px;
    margin: 15px 0;
    width: 100%;
    max-width: 100%;
  }

  .workers-grid {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }

  .legend-items-container {
    gap: 10px;
  }
  
  .legend-item {
    padding: 3px 8px;
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
  }

  .schedule-wrapper {
    padding: 0 10px;
  }

  .worker-card {
    width: calc((100% - 15px) / 2);
    min-width: 150px;
  }

  .row-container {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .employee-cards {
    gap: 10px;
    padding: 0;
  }

  .schedule-table {
    grid-template-columns: 80px repeat(26, 30px);
    font-size: 12px;
  }

  .hour-cell {
    padding: 8px 0;
  }

  .day-cell {
    padding: 20px 0;
  }

  .employee-block {
    font-size: 8px;
    height: 16px;
  }

  .legend-item {
    font-size: 12px;
  }

  .legend-color {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .worker-card {
    width: 100%;
    min-width: unset;
  }

  .todays-workers {
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }

  .workers-grid {
    gap: 10px;
  }

  .legend-item[data-role="ne travaille pas"] span {
    font-size: 0;
  }

  .legend-item[data-role="ne travaille pas"] span::after {
    content: "∅";
    font-size: 14px;
  }
}

/* Ajout des styles pour le conteneur de défilement */
.schedule-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

/* Wrapper pour le tableau de planning */
.schedule-wrapper {
  display: inline-block;
  padding: 0 20px;
}

.schedule-grid {
  margin-top: 20px;
}

.employee-schedule {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.schedule-days {
  display: grid;
  gap: 5px;
}

.schedule-day {
  display: flex;
  gap: 10px;
}

.day-name {
  font-weight: bold;
  min-width: 100px;
}

.invisible {
  visibility: hidden;
  height: 0;
  padding: 0;
  margin: 0;
}

.invisible {
  display: none;
}

/* Ajoutez ces styles pour organiser les boutons */
.popup-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.popup-buttons button {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.close-button {
  margin-top: 10px;
  background-color: #f44336;
  color: white;
  border: none;
}

.legend-section {
  margin-bottom: 1rem;
}

.legend-section h5 {
  margin-bottom: 0.5rem;
}

.legend-role-indicator {
  width: 20px;
  height: 20px;
  border: 2px solid #666;
  border-radius: 4px;
  margin-right: 8px;
}

.legend-item.disabled .legend-role-indicator {
  opacity: 0.3;
}


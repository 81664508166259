.client-id-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .title {
    text-align: center;
    color: #333;
  }
  
  .search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .search-button {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #45a049;
  }
  
  .loading {
    text-align: center;
    font-size: 16px;
    color: #555;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .results-title {
    color: #555;
  }
  
  .result-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    min-height: 400px;
  }
  
  .item {
    margin: 5px 0;
    color: #555;
  }
  
  .no-results {
    text-align: center;
    color: #777;
  }
  
  .idclientstyle {
    font-size: 26px;
    text-align: center;
    margin-bottom: 10px;
  }

  .card-total {
    font-size: 18px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 35px;
  }

  .table-container {
    width: 100%;
    position: relative;
  }

  .clients-table {
    width: 100%;
    border-collapse: collapse;
  }

  .clients-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f5f5f5;
    display: table;
    width: calc(100% - 0.5em);
    table-layout: fixed;
  }

  .clients-table tbody {
    display: block;
    max-height: 400px;
    overflow-y: auto;
  }

  .clients-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateY(0);
  }

  .clients-table tbody tr.fade-out {
    opacity: 0;
    transform: translateY(-10px);
  }

  .clients-table tbody tr.fade-in {
    opacity: 1;
    transform: translateY(0);
  }

  .clients-table th,
  .clients-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .clients-table th {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    position: relative;
  }

  .clients-table th::after {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: bold;
  }

  .clients-table th.sort-asc::after {
    content: "⬆";
  }

  .clients-table th.sort-desc::after {
    content: "⬇";
  }

  @media screen and (max-width: 768px) {
    .client-id-container {
      padding: 10px;
      width: 100%;
      max-width: 100%;
    }

    .table-container {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .clients-table {
      min-width: 600px;
      margin: 0;
    }

    .input {
      width: 90%;
    }
  }
  
/* src/components/admin.css */

body {
    font-family: 'Roboto', Arial, sans-serif;
    background-color: #f7f8fa;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .form-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #34495e;
  }
  
  input,
  textarea,
  select {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
  }
  
  .form-container button[type="submit"] {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #d80000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .form-container button[type="submit"]:hover {
    background-color: #c41e3a;
    transform: scale(1.05);
  }
  
  .popup-confirmation {
    margin-top: 20px;
    padding: 15px;
    background-color: #2ecc71;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    opacity: 0;
    animation: fade-in 0.5s forwards;
  }
  
  .popup-confirmation.fade-in {
    opacity: 1;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .form-container {
      margin: 20px 15px;
      padding: 15px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .form-container button {
      font-size: 0.9rem;
      padding: 10px;
    }
  }


.server-form-container {
    max-width: 700px;
    margin: 40px auto;
    padding: 25px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .server-form-container h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .server-form-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .server-form-container label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #34495e;
  }
  
  .server-form-container input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
  }
  
  .server-form-container button {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #d80000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .server-form-container button:hover {
    background-color: #c41e3a;
    transform: scale(1.05);
  }
  
  .server-form-container button:active {
    background-color: #c41e3a;
  }
  
  .server-form-container .error-message {
    color: red;
    text-align: center;
    margin-top: -10px;
  }
  
  .server-form-container .success-message {
    color: green;
    text-align: center;
    margin-top: -10px;
  }
  
  .server-list-container {
    margin-top: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .server-list-container h3 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .server-list-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .server-list-container li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .server-list-container li:hover {
    background-color: #f1f1f1;
  }
  
  .server-list-container button {
    padding: 8px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #fff;
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .server-list-container button:hover {
    background-color: #c0392b;
  }
  
  .server-list-container button:active {
    background-color: #a93226;
  }
  
  .server-list-container button:first-of-type {
    background-color: #d80000;
    margin-right: 10px;
  }
  
  .server-list-container button:first-of-type:hover {
    background-color: #c41e3a;
  }
  
  .server-list-container button:first-of-type:active {
    background-color: #c41e3a;
  }
  
  @media (max-width: 768px) {
    .server-form-container,
    .server-list-container {
      margin: 20px 15px;
      padding: 15px;
    }
  
    .server-form-container h2,
    .server-list-container h3 {
      font-size: 1.4rem;
    }
  
    .server-list-container li {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      font-size: 0.9rem;
    }
  
    .server-list-container button {
      width: 100%;
      font-size: 0.8rem;
    }
  }

.logout-button {
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .logout-button:hover {
    background-color: #c0392b;
  }
  
  .logout-button:active {
    background-color: #a93226;
  }

  .dish-flex {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }

  .dish-flex > div {
    flex: 1;
    min-width: 0;
  }

  .dish-flex input {
    width: 100%;
    box-sizing: border-box;
  }

  input[type="file"] {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }

  input[type="file"] + label {
    display: inline-block;
    padding: 12px;
    font-size: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: border-color 0.3s ease;
    height: 45px;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    min-width: 0;
    flex: 1;
  }

  input[type="file"] + label:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    input[type="file"] + label {
      padding: 10px;
      font-size: 0.9rem;
    }

    select {
      width: 100%;
      padding: 10px;
      font-size: 0.9rem;
    }
  }

  .addSlideBtn {
    color: black;
    font-size: 1rem;
    font-weight: normal;
    background-color: #f6f6f6;
    border: 1px solid #dadada;
  }
  
  .addSlideBtn:hover {
    color: black;
    background-color: #ebebeb;
    border: 1px solid #cfcfcf;
    transform: scale(1.01);
  }

  .dish-flex label {
    display: block;
    margin-bottom: 5px;
  }

  .popup-selection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-selection-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    max-height: 80vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
  }
  
  .popup-selection-content h3 {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.5rem;
    text-decoration: underline;
  }
  
  .popup-selection-content h4 {
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 20px;
    text-decoration: underline;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  
  .checkbox-container input[type="checkbox"] {
    display: none;
  }
  
  .checkbox-container label {
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    user-select: none;
    line-height: 25px;
    display: flex;
    align-items: center;
  }
  
  .checkbox-container label::before {
    content: "-";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .checkbox-container input[type="checkbox"]:checked + label::before {
    content: "✔";
    transform: translateY(-50%) scale(1.2);
    opacity: 1;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .popup-selection button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
  }
  
  .selection-type {
    font-size: 0.7em;
    color: #666;
    margin-left: 8px;
    font-weight: normal;
  }

  .popup-buttons {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    justify-content: center;
  }
  
  .popup-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.export-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.export-button:hover {
  background-color: #45a049;
}

@media screen and (max-width: 768px) {
  .export-button {
    display: none;
  }
}
.admin-home {
    padding: 20px;
    text-align: center;
  }
  
  .charts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chart-container {
    width: 80%;
    max-width: 1200px;
    min-height: 470px;
    height: 100%;
    margin: 20px auto;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .admin-home canvas {
    width: 80% !important;
    height: 70% !important;
    aspect-ratio: 16/9;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .admin-home .chart-container {
      min-height: 200px;
      height: 100%;
      padding: 10px;
      margin: 10px 0px;
    }

    .admin-home canvas {
      max-width: 100%;
      height: auto !important;
    }
  }
  